import { html } from '@tankbar/dom-utils';

const combinr = {
    hasAutoloaded: false,
    frame: null,
};

init();
autoload();
document.addEventListener('DOMContentLoaded', autoload);
document.addEventListener('scroll', onScroll);

function parseOptions(node) {
    const product = node.dataset.product;
    const base = node.dataset.base;
    const lang = node.dataset.lang ?? 'se';

    return {
        base,
        product,
        lang,
    };
}

function init() {
    window.addEventListener('message', onMessageReceived);
}

function autoload() {
    if (combinr.hasAutoloaded) {
        return;
    }

    const node = document.getElementById('combinr-root');
    if (!node || node.dataset.auto === 'false') {
        return;
    }

    combinr.hasAutoloaded = true;

    start(node);
}

function render(node) {
    const options = parseOptions(node);

    const fragment = html`
        <iframe
            src="https://${options.base}/p/${options.product}/?l=${options.lang}"
            scrolling="no"
            frameborder="0"
            style="scroll-margin-top: 24px"
        ></iframe>
    `;

    const frame = fragment.children[0];

    node.innerHtml = '';
    node.appendChild(frame);

    return frame;
}

function start(node) {
    combinr.frame = render(node);
}

function onScroll() {
    if (combinr.frame == null) {
        return;
    }

    combinr.frame.contentWindow.postMessage({
        combinr: true,
        action: 'scroll',
        viewport: {
            width: window.innerWidth,
            height: window.innerHeight,
            scrollTop: window.scrollY || window.pageYOffset,
        },
        frame: {
            offsetTop: combinr.frame.offsetTop,
        },
    }, '*');
}

function onMessageReceived(e) {
    if (combinr.frame == null || !e.data || e.data.combinr == null) {
        return;
    }

    switch (e.data.action) {
        case 'init':
            combinr.frame.style.height = `${e.data.height}px`;

            requestAnimationFrame(onScroll);

            break;
        case 'size':
            combinr.frame.style.height = `${e.data.height}px`;

            break;
        case 'position':
            combinr.frame.scrollIntoView({
                block: e.data.block,
                behavior: 'smooth',
            });

            break;
    }
}
